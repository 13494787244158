/**
 * Headline Component
 * 
 * The `Headline` component is a customizable text rendering component that utilizes the `class-variance-authority` library
 * to apply different headline styles. It allows you to define various headline variants and apply them to a `<span>` element.
 * 
 * @module Headline
 */

import React, { FC, forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from 'src/app/helpers/index';

/**
 * Define headline variants using `cva` function from `class-variance-authority`.
 */
const headlineVariants = cva('text-[#212B36]', {
  variants: {
    variant: {
      headline1: 'text-6xl font-semibold',
      headline2: 'text-5xl font-semibold',
      headline3: 'text-3xl font-semibold',
      headline4: 'text-2xl font-semibold',
      headline5: 'text-xl font-semibold',
      headline6: 'text-lg font-semibold',
    },
  },
  defaultVariants: {
    variant: 'headline1',
  },
});

/**
 * Props for the `Headline` component.
 */
export interface HeadlineProps
  extends React.LabelHTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof headlineVariants> {}

/**
 * `Headline` Component
 * 
 * The `Headline` component renders customizable headline text within a `<span>` element, applying the specified headline
 * variant's styling using the `class-variance-authority` library.
 * 
 * @example
 * ```jsx
 * <Headline variant="headline1" className="custom-class">
 *   Hello, world!
 * </Headline>
 * ```
 * 
 * @component
 */
export const Headline: FC<HeadlineProps> = forwardRef<HTMLSpanElement, HeadlineProps>(
  ({ variant, className, children, ...props }, ref) => {
    // Generate class names using `headlineVariants` and `cn` helper function.
    const classNames = cn(headlineVariants({ variant }), className);

    return (
      <span ref={ref} className={classNames} {...props}>
        {children}
      </span>
    );
  }
);

/**
 * Default export of the `Headline` component.
 */
export default Headline;
