import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'

const MasterLayout = () => {
  const location = useLocation()
  const [showSidebar, setShowSidebar] = useState<boolean>(true)

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  /**
   * Usage for handling show or not side bar,
   * usally usage in pelayanan page for handling
   * sidebar show with conditional
   */
  useEffect(() => {
    const handleAttributeChange = () => {
      const appSidebarFixed = document.querySelector('[data-kt-app-sidebar-fixed]')
      if (appSidebarFixed) {
        const attributeValue = appSidebarFixed.getAttribute('data-kt-app-sidebar-fixed')
        setShowSidebar(attributeValue === 'true' ? true : false)
      }
    }

    /* Initial Setup */
    handleAttributeChange()

    /* Attach the event listener */
    const appSidebarFixed = document.querySelector('[data-kt-app-sidebar-fixed]')
    appSidebarFixed?.addEventListener('data-kt-app-sidebar-fixed-change', handleAttributeChange)

    return () => {
      appSidebarFixed?.removeEventListener(
        'data-kt-app-sidebar-fixed-change',
        handleAttributeChange
      )
    }
  }, [showSidebar])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              {showSidebar && <Sidebar />}
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <ToolbarWrapper />
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
