import { ADJUSTMENT_SERVICE_MENU } from '../_data'
import { IMenu } from '../_menu-config'

export const DaftarSPPD: IMenu[] = [
  {
    subItems: [
      {
        title: 'Daftar SPPD',
        guards: ['operator', 'superadmin'],
        link: 'applications/application-list',
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        isVisible: true,
        icon: 'scroll',
        guardSubBidang: ['pendataan & penetapan'],
        subItems: [
          {
            title: 'Permohonan Masuk',
            // rightTextCodeEl: 'DPMP',
            link: 'applications/daftar-sppd/permohonan-masuk',
          },
          {
            title: 'Yang Diverifikasi',
            guards: ['operator'],
            guardBidang: ['pendataan & penetapan'],
            // rightTextCodeEl: 'DPPDP',
            link: 'applications/daftar-sppd/selesai',
          },
          {
            title: 'Yang Divalidasi',
            guards: ['operator'],
            guardBidang: ['pendataan & penetapan'],
            // rightTextCodeEl: 'DPD',
            link: 'applications/daftar-sppd/validasi',
          }
        ],
      },
      {
        title: 'Daftar SPPD',
        guards: ['kasubbid'],
        link: 'applications/application-list',
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        isVisible: true,
        icon: 'scroll',
        guardSubBidang: ['pendataan & penetapan'],
        subItems: [
          {
            title: 'Permohonan Masuk',
            // rightTextCodeEl: 'DPMP',
            link: 'applications/daftar-sppd/permohonan-masuk',
          },
          {
            title: 'Yang Divalidasi',
            // rightTextCodeEl: 'DPPDP',
            link: 'applications/daftar-sppd/selesai',
          },
        ],
      },
      {
        title: 'Daftar SPPD',
        guards: ['kabid'],
        link: 'applications/application-list',
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        isVisible: true,
        icon: 'scroll',
        guardSubBidang: ['pendataan & penetapan'],
        subItems: [
          {
            title: 'Permohonan Masuk',
            // rightTextCodeEl: 'DPMP',
            link: 'applications/daftar-sppd/permohonan-masuk',
          },
          {
            title: 'Yang Ditetapkan',
            // rightTextCodeEl: 'DPPDP',
            link: 'applications/daftar-sppd/selesai',
          },
        ],
      },
    ],
  },
]
