import {FC} from 'react'
import {Table} from 'react-bootstrap'
import {RealisasiTable} from 'src/app/services/realisasi/type'

interface PajakTableProps {
  data: RealisasiTable[]
}

export const PajakTable: FC<PajakTableProps> = ({data}) => {
  return (
    <Table className='w-100'>
      <thead>
        <tr className='border bg-[#4f858c] px-3'>
          <th className='text-white w-[50%] fw-bold'>Nama Pajak</th>
          <th className='text-white fw-bold'>Target</th>
          <th className='text-white fw-bold'>Realisasi</th>
          <th className='text-white fw-bold'>RKUD</th>
        </tr>
      </thead>
      <tbody className='w-100'>
        {data.map((item, index) => (
          <tr className='border px-3' key={index}>
            <td className='text-start'>{item.pajak}</td>
            <td className='text-right'>{item.target.toLocaleString('id-ID')}</td>
            <td className='text-right'>{item.realisasi.toLocaleString('id-ID')}</td>
            <td className='text-right'>{item.rkud.toLocaleString('id-ID')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
