import {FC} from 'react'
import {ChartRealisasi} from '../components/realisasi/ChartRealisasi'
import {QueueRealisasi} from '../components/realisasi/QueueRealisasi'
import Headline from 'src/app/widgets/atomic/headline'
import {useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import {RealisasiService} from 'src/app/services/realisasi/service'

/**
 * Get greeting word
 *
 * @returns {string}
 */
const getHour = (): string => {
  const hour = new Date().getHours()
  let message: string = ''

  if (hour >= 5 && hour < 12) message = 'Selamat Pagi'
  else if (hour >= 12 && hour < 17) message = 'Selamat Siang'
  else message = 'Selamat Malam'

  return message
}

/**
 * Wraping class styling
 */
const className = {
  container: 'relative min-h-[90vh] md:min-h-[94vh]',
  containerTitle: 'h-[50vh] flex flex-col bg-[#4f858c] w-100',
  titleGreeting: 'text-3xl text-white ml-7 md:ml-20 mt-10',
  subTitleContainer: 'ml-7 md:ml-20 mr-7 md:mr-20 flex justify-between',
  subTitleGreeting: 'text-xl text-[#fafafa96] font-normal',
  subTitleBeranda: 'text-xl cursor-pointer text-[#fafafa96] font-normal hover:text-white',
  containerContent:
    'absolute flex md:flex-row flex-col md:gap-5 top-[95%] md:top-[55%] left-[50%] w-[92%] translate-x-[-50%] translate-y-[-44%]',
}

/**
 * This page for showing data realtime realisasi
 *
 * @returns {React.ReactElement}
 */
export const RealisasiPage: FC = () => {
  const navigate = useNavigate()
  const realisasiService = new RealisasiService()

  const {data, isFetching} = useQuery(['realisasi'], () => realisasiService.listing(), {
    refetchInterval: 5000,
    refetchOnWindowFocus: false
  })

  return (
    <div className={className.container}>
      <div className={className.containerTitle}>
        <Headline className={className.titleGreeting}>{getHour()}</Headline>
        <div className={className.subTitleContainer}>
          <Headline className={className.subTitleGreeting}>
            Data Realtime Transaksi Pembayaran Pajak Daerah.
          </Headline>
          <Headline className={className.subTitleBeranda} onClick={() => navigate('/')}>
            Beranda
          </Headline>
        </div>
      </div>
      <div className={className.containerContent}>
        <ChartRealisasi data={data?.data.totalTransaksi ?? []} table={data?.data.table ?? []} />
        <QueueRealisasi
          data={data?.data.terbaru ?? []}
          totalBulanIni={data?.data.totalBulanIni ?? 0}
          totalHariIni={data?.data.totalHariIni ?? 0}
          totalSemua={data?.data.totalSemua ?? 0}
          isFeching={isFetching}
        />
      </div>
    </div>
  )
}
