import { IMenu } from "../_menu-config";

export const Koreksi: IMenu[] = [
  {
    title: "Koreksi",
    link: 'koreksi',
    guards: ['superadmin'],
    subItems: [
      {
        title: "Koreksi Pembayaran",
        guards: ['superadmin', 'admin'],
        icon: 'finance-calculator',
        fontIcon: 'bi-app-indicator',
        link: 'koreksi/koreksi-pembayaran'
      }
    ]
  }
]