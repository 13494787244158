import {ApexOptions} from 'apexcharts'
import {FC, memo} from 'react'
import ReactApexChart from 'react-apexcharts'
import {Card} from 'react-bootstrap'
import {Carousel} from 'react-responsive-carousel'
import {RealisasiTable, RealisasiTotalTransaksi} from 'src/app/services/realisasi/type'
import Headline from 'src/app/widgets/atomic/headline'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {PajakTable} from './PajakTable'

/**
 * Type Props for Chart Realisasi
 */
interface ChartRealisasiProps {
  data: RealisasiTotalTransaksi[]
  table: RealisasiTable[]
}

/**
 * Chart Realisasi
 *
 * This component usage for handling ui data for chart from realisasi
 * page
 * @returns {React.ReactElement}
 */
export const ChartRealisasi: FC<ChartRealisasiProps> = memo(({data, table}) => {
  const options: ApexOptions = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data.map((d) => d.tanggal),
    },
    plotOptions: {
      area: {
        fillTo: 'origin',
      },
    },
  }

  const series: ApexAxisChartSeries = [
    {
      name: 'Realisasi',
      data: data.map<number>((d) => parseInt(d.realisasi)),
    },
  ]

  return (
    <div className='md:w-[70%] sm:mb-10'>
      <Carousel showStatus={false} interval={5000} autoPlay autoFocus emulateTouch infiniteLoop showArrows={false}>
        <Card className='shadow-md py-5 w-100 md:w-[80%]'>
          <Headline className='text-2xl ml-5'>Total Transaksi</Headline>
          <ReactApexChart type='area' height={400} options={options} series={series} />
        </Card>
        <Card className='shadow-md py-5 h-[72vh] w-100 md:w-[80%]'>
          <Headline className='text-2xl ml-5'>Total Transaksi</Headline>
          <div className='px-5'>
            <PajakTable data={table} />
          </div>
        </Card>
      </Carousel>
    </div>
  )
})
