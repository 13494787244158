export class AppLoading {

  /**
   * Show Page Loading.
   * This component usage for handling loading for no action in run when in loading
   */
  public static showPageLoading = (msg?: string): void => {
    document.body.classList.add("page-loading")
    document.body.setAttribute("data-kt-app-page-loading", "on")

    const loadingEl = document.createElement("div");
    loadingEl.setAttribute('id', 'loading-frezee-screen')
    document.body.prepend(loadingEl);
    loadingEl.classList.add("page-loader");
    loadingEl.classList.add("flex-column");
    loadingEl.classList.add("bg-dark");
    loadingEl.classList.add("bg-opacity-25");
    

    const message: string = msg || 'Memuat...'


    loadingEl.innerHTML = `
      <div class='bg-white rounded-md p-10 flex flex-col justify-center items-center'>
        <span class="spinner-border text-primary" role="status"></span>
        <span class="text-gray-800 fs-6 fw-semibold mt-5">${message}</span>
      </div>
    `;
  }


  /**
   * Hide Page Loading.
   * This component usaage for handling hidde loading
   */
  public static hidePageLoading = (): void => {
    document.body.classList.remove("page-loading")
    document.body.removeAttribute("data-kt-app-page-loading")


    const loadingEl = document.querySelector('#loading-frezee-screen')
    loadingEl.remove()
  }


  public static showContentLoading = (msg?: string): void => {
    const documentContent = document.body.querySelector('#kt_app_main')
    documentContent.classList.add("content-loading", "relative")
    documentContent.setAttribute("data-kt-app-content-loading", "on")

    const loadingEl = document.createElement("div");
    loadingEl.setAttribute('id', 'loading-frezee-screen')
    documentContent.prepend(loadingEl);
    loadingEl.classList.add("content-loader");
    loadingEl.classList.add("flex-column");
    loadingEl.classList.add("bg-dark");
    loadingEl.classList.add("bg-opacity-25");
    

    const message: string = msg || 'Memuat...'


    loadingEl.innerHTML = `
      <div class='bg-white rounded-md p-10 flex flex-col justify-center items-center'>
        <span class="spinner-border text-primary" role="status"></span>
        <span class="text-gray-800 fs-6 fw-semibold mt-5">${message}</span>
      </div>
    `;
  }


  public static hideContentLoading = (): void => {
    const documentContent = document.body.querySelector('#kt_app_main')
    documentContent.classList.remove("content-loading")
    documentContent.removeAttribute("data-kt-app-content-loading")

    const loadingEl = document.querySelector('#loading-frezee-screen')
    loadingEl.remove() 
  }


  public static showElementLoading = (msg?: string, attributName?: string) => {
    const documentElement = document.body.querySelector(`[${attributName ?? 'data-kt-element-loading'}]`)
    documentElement.classList.add('relative')
    documentElement.classList.add('overflow-hidden')

    const loadingEl = document.createElement("div");
    loadingEl.setAttribute('id', 'loading-frezee-screen')
    documentElement.prepend(loadingEl);
    loadingEl.classList.add("absolute");
    loadingEl.classList.add("w-100");
    loadingEl.classList.add("h-100");
    loadingEl.classList.add("flex");
    loadingEl.classList.add("justify-center");
    loadingEl.classList.add("items-center");
    loadingEl.classList.add("z-[99]");
    loadingEl.classList.add("bg-[#00000017]");
    loadingEl.classList.add("cursor-wait");
    

    const message: string = msg || 'Memuat...'


    loadingEl.innerHTML = `
      <div class='bg-white rounded-md p-10 flex shadow-sm flex-col justify-center items-center'>
        <span class="spinner-border text-primary" role="status"></span>
        <span class="text-gray-800 fs-6 fw-semibold mt-5">${message}</span>
      </div>
    `;
  }


  public static hideElementLoading = (attributName?: string) : void => {
    try {
      const loadingEl = document.querySelector('#loading-frezee-screen')
      const documentElement = document.body.querySelector(`[${attributName ?? 'data-kt-element-loading'}]`)

      documentElement.classList.remove('overflow-hidden')
      loadingEl?.remove()
    } catch {}
  }
}