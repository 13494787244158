import { ADJUSTMENT_SERVICE_MENU } from "../_data";
import { IMenu } from "../_menu-config";

export const Lapangan: IMenu[] = [
  {
    title: 'Lapangan',
    guards: [],
    adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
    subItems: [
      {
        title: 'Pemetaan Kolektor',
        guards: [],
        link: 'lapangan/pemetaan-kolektor',
        fontIcon: 'duotone',
        icon: 'more-2',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP]
      }
    ]
  }
]