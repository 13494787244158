/* eslint-disable @typescript-eslint/no-unused-expressions */
import {FC, FormEvent, useState} from 'react'
import {Button, Col, Form} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../auth'
import {login} from '../../auth/core/_requests'
import {AuthFormInput, AuthProps} from '../core/_home'
import IUserService from 'src/app/services/user/service'
import ISubBidangService from 'src/app/services/sub-bidang/service'

const Auth: FC<AuthProps> = ({className, onTab}) => {
  const navigate = useNavigate()
  const [validated, setValidated] = useState<boolean>(false)
  const {saveAuth, setCurrentUser, setPermission} = useAuth()
  const [formInput, setFormInput] = useState<AuthFormInput>({
    username: '',
    password: '',
  })

  /**
   *
   * For check validation Input
   */
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()

    if (form.checkValidity() === false) {
      event.stopPropagation()
    }

    setValidated(true)

    if (form.checkValidity() === true) {
      postLogin()
    }
  }

  /**
   * For handle post login
   */
  const postLogin = async () => {
    try {
      const {data: auth} = await login(formInput.username, formInput.password)
      saveAuth(auth)
      setCurrentUser(auth.user)
      setPermission(auth.permission)
      if ([IUserService.KABID].includes(auth.user.role)) {
        navigate('/pelayanan/layanan')
        return
      }
      if ([IUserService.OPERATOR, IUserService.NOTARIS].includes(auth.user.role)) {
        if (IUserService.NOTARIS !== auth.user.role) {
          if (
            auth.user.nama_sub_bidang.toLowerCase() === ISubBidangService.SUB_BIDANG_OPERATOR_BPHTB
          ) {
            navigate('/dashboard')
            return
          }
        }

        if (auth.user.nama_sub_bidang === IUserService.PENATAAN_PBB) {
          console.log('mas')
          // navigate('/applications/application-list/all')
          navigate('/dashboard')
          return
        }

        navigate('/pelayanan/form-service')
        return
      }

      navigate('/dashboard')
    } catch (error) {
      saveAuth(undefined)
    }
  }

  /**
   *
   * for handle input and update state
   */
  const handleOnChange = (event: any) => {
    const {name, value} = event?.target
    setFormInput((prevObject) => ({
      ...prevObject,
      [name]: value,
    }))
  }

  return (
    <div className={className}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Col} controlId='username'>
          <br />
          <Form.Label>Username</Form.Label>
          <Form.Control
            required
            name='username'
            type='text'
            onChange={handleOnChange}
            placeholder='Username'
          />
          <Form.Control.Feedback type='invalid'>Username wajib diisi.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId='password' className='mt-3'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            name='password'
            type='password'
            onChange={handleOnChange}
            placeholder='Password'
          />
          <Form.Control.Feedback type='invalid'>Password wajib diisi.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='d-flex mt-5 justify-content-end'>
          <button onClick={() => onTab()} className='me-5 btn btn-outline-primary'>
            Batal
          </button>
          <Button type='submit'>Masuk</Button>
        </Form.Group>
      </Form>
    </div>
  )
}

export {Auth}
