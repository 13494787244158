import { ADJUSTMENT_SERVICE_MENU } from '../_data'
import { IMenu } from '../_menu-config'

export const ApplicationsPbb: IMenu[] = [
  {
    title: 'Applications Pbb',
    guards: [
      'superadmin',
      'kecamatan',
      'admin_kolektor',
      'kelurahan',
      'kasubbid',
      'kabid',
      'admin',

    ],
    guardSubBidang: ['pendataan pbb'],
    adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
    subItems: [
      {
        title: 'Penetapan SPPT',
        fontIcon: 'duotone',
        icon: 'award',
        link: 'applications/penetapan-sppt',
        guards: ['admin', 'superadmin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'Update NJOP',
        fontIcon: 'duotone',
        icon: 'setting',
        link: 'applications/update-njop',
        guards: ['admin', 'superadmin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'Pembatalan Denda',
        fontIcon: 'duotone',
        icon: 'abstract-47',
        link: 'applications/pembatalan-denda',
        guards: ['superadmin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'Berkas Permohonan',
        guards: ['kabid', 'kasubbid'],
        link: 'applications/berkas-permohonan',
        fontIcon: 'duotone',
        guardSubBidang: ['pelayanan'],
        icon: 'bank',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        isVisible: true,
        subItems: [
          {
            title: 'Baru',
            link: 'applications/berkas-permohonan/baru',
          },
          {
            title: 'Ditolak',
            link: 'applications/berkas-permohonan/ditolak',
          },
          {
            title: 'Diverifikasi',
            link: 'applications/berkas-permohonan/verifikasi',
          },
        ],
      },
      {
        title: 'Pencetakan',
        icon: 'book',
        guardSubBidang: ['pelayanan', 'pendataan pbb'],
        fontIcon: 'duotone',
        link: 'applications/pencetakan',
        guards: ['operator', 'superadmin'],
        subItems: [
          {
            title: 'Cetak SKNJOP',
            link: 'applications/pencetakan/cetak-njop',
          },
          {
            title: 'Cetak STTS',
            link: 'applications/pencetakan/cetak-stts',
          },
          {
            title: 'Pencetakan SPPT',
            link: 'applications/pencetakan/pencetakan-sppt',
          },
          {
            title: 'SPPT Buku 4.5',
            link: 'applications/pencetakan/sppt-buku-45',
          },
          {
            title: 'Cetak Massal',
            link: 'applications/pencetakan/cetak-massal',
          },
        ],
      },
      {
        title: 'Data Rekap Wajib Pajak',
        guards: ['operator', 'kabid', 'admin', 'superadmin', 'admin_kolektor'],
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        icon: 'clipboard',
        guardSubBidang: ['pelayanan', 'pendataan pbb'],
        link: 'applications/data-rekap-wajib-pajak',
        subItems: [
          {
            title: 'Cari Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/list-wajib-pajak',
          },
          {
            title: 'Histori Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/history-wajib-pajak',
          },
        ],
      },
      {
        title: 'Koreksi Pembayaran',
        guards: ['superadmin'],
        guardSubBidang: ['pendataan pbb', 'pelayanan'],
        link: 'applications/koreksi-pembayaran',
        icon: 'barcode',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      // {
      //   title: 'Peta Objek Pajak',
      //   guards: ['superadmin'],
      //   fontIcon: 'duotone',
      //   guardSubBidang: ['pelayanan', 'pendataan pbb'],
      //   icon: 'map',
      //   link: 'https://smartgis.infotekmetrodata.co.id/',
      // },
      // {
      //   title: 'Pemutahiran',
      //   guards: ['superadmin'],
      //   fontIcon: 'duotone',
      //   guardSubBidang: ['pelayanan', 'pendataan pbb'],
      //   icon: 'book-open',
      //   link: 'applications/pemutahiran',
      // },
      {
        title: 'Tagihan Kolektor',
        guards: ['superadmin', 'admin', 'kabid', 'admin_kolektor'],
        fontIcon: 'duotone',
        guardSubBidang: ['pelayanan', 'pendataan pbb'],
        icon: 'lots-shopping',
        link: 'applications/tagihan-kolektor',
      },
      {
        title: 'Laporan ',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        guards: ['operator', 'kabid', 'kasubbid', 'admin', 'superadmin'],
        fontIcon: 'duotone',
        icon: 'graph-4',
        link: 'applications/laporan/laporan-pbb',
        subItems: [
          {
            title: 'Ketetapan',
            link: 'applications/laporan/laporan-pbb/ketetapan',
          },
          {
            title: 'Realisasi',
            link: 'applications/laporan/laporan-pbb/realisasi',
          },
          {
            title: 'Piutang',
            link: 'applications/laporan/laporan-pbb/piutang',
          },
          {
            title: 'DHKP',
            link: 'applications/dhkp',
          },
        ],
      },
      {
        title: 'DHKP',
        guardSubBidang: ['pendataan pbb'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        guards: ['kabid', 'kasubbid', 'admin', 'superadmin', 'kecamatan', 'kelurahan'],
        fontIcon: 'duotone',
        icon: 'financial-schedule',
        link: 'applications/dhkp',
      },
    ],
  },
]
