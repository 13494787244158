/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios from 'axios'
import {ClassValue} from 'class-variance-authority/dist/types'
import clsx from 'clsx'
import {twMerge} from 'tailwind-merge'
import moment from 'moment'

/**
 * Do conversion from object data to form data
 * @param data object
 * @returns FormData
 */
export const objectToFormData = (data: any): FormData => {
  const formData: FormData = new FormData()

  for (const key in data) {
    if (data[key]) {
      formData.append(key, data[key])
    }
  }

  return formData
}

// Fungsi untuk menambah seminggu pada tanggal
export const addOneWeek = (dateString) => {
  // Pisahkan string tanggal menjadi hari, bulan, tahun
  const [day, month, year] = dateString.split('/').map(Number)

  // Buat objek Date dengan nilai yang dipisah
  const date = new Date(year, month - 1, day)

  // Tambahkan 7 hari
  date.setDate(date.getDate() + 7)

  // Kembalikan ke format dd/MM/yyyy
  const newDay = String(date.getDate()).padStart(2, '0')
  const newMonth = String(date.getMonth() + 1).padStart(2, '0')
  const newYear = date.getFullYear()

  return `${newDay}/${newMonth}/${newYear}`
}

/**
 * For formate date to (day, date mount year)
 *
 * @param {string} dateString - date string in Indonesia
 * @returns {string}
 */
export const formatDate = (dateString: string): string => {
  const dayNames = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
  const monthNames = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]

  const dateObj = new Date(dateString)
  const dayName = dayNames[dateObj.getDay()]
  const date = dateObj.getDate()
  const monthName = monthNames[dateObj.getMonth()]
  const year = dateObj.getFullYear()

  return `${dayName}, ${date} ${monthName} ${year}`
}

/**
 * Formats a date string according to the specified country and format.
 *
 * @param {string} dateTime - The date string to format.
 * @param {string} [country='id'] - The country code to use for formatting. Defaults to 'id'.
 * @param {string} [format='DD MMMM YYYY'] - The format string to use for formatting. Defaults to 'DD MMMM YYYY'.
 * @return {string} The formatted date string.
 */
export const customeFormatDate = (
  dateTime: string,
  country: string = 'id',
  formatDate: string = 'DD MMMM YYYY'
) => {
  let dateTimes = moment(dateTime)

  dateTimes.locale(country)
  return dateTimes.format(formatDate)
}

/**
 * Format date to latest like in today format number will to time
 * for yesterday will to yester
 * and to three days ago would be the date
 *
 * in Indonesia format
 *
 * @param {string} dateString - (day, date mount year ; time timeSetting(pagi|siang|malam))
 * @returns {string}
 */
export const formatDateLatest = (dateString?: string): string => {
  const event = new Date()
  let day, date, month, year, time

  if (!dateString) {
    dateString = new Date().toString()
  }

  try {
    ;[day, date, month, year, time] = (() => {
      const dayPart = dateString.split(',')[0]
      const [datePart, monthPart, yearPart] = dateString
        .split(',')[1]
        .trim()
        .split(' ')
        .splice(0, 3)
      let timePart: any = dateString.split(';')[1].trim().split(' ')

      timePart = `${timePart[0]} ${timePart[1]}`

      return [dayPart, datePart, monthPart, yearPart, timePart]
    })()
  } catch (error) {
    // Handle invalid dateString format
    console.error('Invalid dateString format', error)
    return 'Invalid date'
  }

  // Handle today
  if (
    event.getFullYear().toString() === year &&
    event.toLocaleDateString('id-ID', {month: 'long'}) === month &&
    event.getDate().toString() === date
  ) {
    const [hour, minutes] = time.split(':')
    return `${hour}:${minutes} ${time.split(' ').pop()}`
  }

  // Handle yesterday
  if (
    event.getFullYear().toString() === year &&
    event.toLocaleDateString('id-ID', {month: 'long'}) === month &&
    parseInt(date) - 1 !== 0 &&
    parseInt(date) - 1 === event.getDate() - 1
  ) {
    return 'Kemarin'
  }

  // Handle different month
  if (event.getFullYear().toString() === year) {
    return `${month} ${date}`
  }

  return `${year} ${month} ${date}`
}

/**
 * For show notification (toast)
 *
 * @param {string} message - message to show in notification
 * @param {'success' | 'danger'} type - type notification
 * @param {void}
 */
export const showAlert = (message: string, type: 'success' | 'danger', duration?: number) => {
  const toastContainer = document.body

  const toastElemennt = `
    <div class="tast-container position-fixed bottom-0 end-0 p-3" style="z-index: 1060">
      <div class="toast" style="display: block !important" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <div class="rounded me-2 bg-${type}" style="width: 20px; height: 20px"></div>
          <strong class="me-auto">Notifikasi</strong>
          <small>now</small>
          <button
            type="button" 
            id="btn-close-alert-notification"
            class="btn-close" 
            data-bs-dismiss="toast" 
            aria-label="Close">X</button>
        </div>
        <div class="toast-body" style="white-space: pre-wrap">${message}</div>
      </div>
    </div>
  `

  const toast = document.createElement('div')
  toast.innerHTML = toastElemennt
  toastContainer.appendChild(toast)

  const getBtnCloseToast = document.querySelectorAll('#btn-close-alert-notification')
  const alertContainer = document.querySelector('.tast-container')

  // handle for automate close
  let clearTime = false
  let closeTime: NodeJS.Timeout

  const setTimeOut = () => {
    closeTime = setTimeout(() => {
      alertContainer?.remove()
    }, duration ?? 2000)
  }

  setTimeOut()

  getBtnCloseToast.forEach((element) => {
    // handle click for close toach (notification)
    element?.addEventListener('click', () => {
      const alertContainer = document.querySelector('.tast-container')
      alertContainer?.remove()
    })
  })

  // handle hover cencel close toach
  alertContainer?.addEventListener('mouseover', () => {
    if (!clearTime) {
      clearTimeout(closeTime!)
      clearTime = true
    }
  })

  // handle running again after out over
  alertContainer?.addEventListener('mouseout', () => {
    if (clearTime) {
      setTimeOut()
      clearTime = false
    }
  })
}

export const formatNop = (nop) => {
  if (!nop) return '-'
  return `${nop.slice(0, 2)}.${nop.slice(2, 4)}.${nop.slice(4, 7)}.${nop.slice(7, 10)}.${nop.slice(
    10,
    13
  )}.${nop.slice(13, 17)}.${nop.slice(17, 18)}`
}

/**
 * Injection inner text in sidebar
 * for add badge in sidebarmenu
 *
 * @param {string} atributUniqueValue - value of the e-sidebar-menu-badge
 * @param {string} text - text will be displayed on the badge
 */
export const injectInnerTextSideBar = (atributUniqueValue: string, text: string): void => {
  const badgeSideBar: Element | null = document.querySelector(
    `[e-sidebar-menu-badge='${atributUniqueValue}']`
  )
  if (badgeSideBar) {
    badgeSideBar!.innerHTML = text
  }
}

/**
 * get configrate ip and name printer
 *
 * @returns {{
 *    ip: string
 *    printer: string
 *  }}
 */
export const getPrinterConfig = async () => {
  let printerConfig = localStorage.getItem('printer-config')
  if (printerConfig) {
    return JSON.parse(printerConfig)
  } else {
    const data = await axios.get('setting/data').then((res) => {
      const data = res.data?.data.filter(
        (data: any) =>
          data.category === 'shared ip' ||
          data.category === 'printer name' ||
          data.category === 'folder print'
      )
      // eslint-disable-next-line array-callback-return
      const wrapingPrinterConfig = data.map((data: any) => {
        if (data.category === 'shared ip') {
          return {
            ip: data.description,
          }
        }

        if (data.category === 'printer name') {
          return {
            printer: data.description,
          }
        }

        if (data.category === 'folder print') {
          return {
            folderInput: data.description,
          }
        }
      })

      localStorage.setItem(
        'printer-config',
        JSON.stringify({
          ...wrapingPrinterConfig[0],
          ...wrapingPrinterConfig[1],
          ...wrapingPrinterConfig[2],
        })
      )

      return JSON.parse(localStorage.getItem('printer-config')!)
    })

    return data
  }
}

/**
 * Do sanitize object to string, Generally use for
 * sanitize validate
 *
 * @param {Object} data
 * @returns {string}
 */
export const serilizeObjectToString = (data: Object): string => {
  let dataBuild: string = ''
  type ObjectType = keyof typeof data

  if (typeof data == 'string') {
    return data
  }

  Object.keys(data).forEach((key: string) => {
    dataBuild = dataBuild + `${key}: `

    const cleanKey = key as ObjectType
    if (Array.isArray(data[cleanKey])) {
      const messages = data[cleanKey] as any
      messages.forEach((msg: string, index: number) => {
        dataBuild = dataBuild + `${msg}\n`
      })
    }
  })

  return dataBuild
}

/**
 * In function for handling tailwinds conflict
 *
 * @param inputs - ClassValue
 * @returns {string}
 */
export const cn = (...inputs: ClassValue[]): string => {
  return twMerge(clsx(inputs))
}

/**
 * Convert a numerical value into its Indonesian word representation.
 * @param num The number to convert.
 * @returns The Indonesian word representation of the number.
 */
export const numberToWords = (num: number): string => {
  const units = ['', 'satu', 'dua', 'tiga', 'empat', 'lima', 'enam', 'tujuh', 'delapan', 'sembilan']
  const teens = [
    'sepuluh',
    'sebelas',
    'dua belas',
    'tiga belas',
    'empat belas',
    'lima belas',
    'enam belas',
    'tujuh belas',
    'delapan belas',
    'sembilan belas',
  ]
  const tens = [
    '',
    'sepuluh',
    'dua puluh',
    'tiga puluh',
    'empat puluh',
    'lima puluh',
    'enam puluh',
    'tujuh puluh',
    'delapan puluh',
    'sembilan puluh',
  ]
  const thousands = ['', 'ribu', 'juta', 'miliar', 'triliun'] // Add more as needed

  if (num === 0) {
    return 'nol'
  }

  const chunks = []
  let i = 0

  while (num > 0) {
    const chunk = num % 1000
    if (chunk > 0) {
      let chunkStr = ''

      if (chunk >= 100) {
        chunkStr += units[Math.floor(chunk / 100)] + ' ratus'
      }

      const remainder = chunk % 100
      if (remainder > 0) {
        if (remainder < 10) {
          chunkStr += ' ' + (units[remainder] ?? '')
        } else if (remainder < 20) {
          chunkStr += teens[remainder - 10] ?? ''
        } else {
          chunkStr += ' ' + tens[Math.floor(remainder / 10)]
          if (remainder % 10 > 0) {
            chunkStr += ' ' + units[remainder % 10] ?? ''
          }
        }
      }

      chunkStr += ' ' + (thousands[i] ?? '')
      chunks.unshift(chunkStr)
    }

    num = Math.floor(num / 1000)
    i++
  }

  return chunks.join(' ')
}

export const removeKeys = <T extends Record<string, any>>(obj: T, keysToRemove: string[]) => {
  const copy = {...obj}
  keysToRemove.forEach((key) => delete copy[key])
  return copy
}
