import { IMenu } from "../_menu-config";

export const Piutang: IMenu[] = [
  {
    subItems: [
      {
        title: 'Piutang ',
        guards: ['kecamatan', 'kelurahan'],
        link: 'applications/data-rekap-wajib-pajak/piutang',
        fontIcon: 'duotone',
        icon: 'data',
      },
    ],
  },
]