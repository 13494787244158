import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment, {invalid} from 'moment'
import {FC, useMemo} from 'react'
import {Card} from 'react-bootstrap'
import {useQuery} from 'react-query'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {SpptSerice} from 'src/app/services/sppt/service'
import {Badge} from 'src/app/widgets/atomic/badge'
import Div from 'src/app/widgets/atomic/div'
import {Footer} from '../layout/footer'

const CekPembayaranPBB: FC = () => {
  let query = useLocation()
  const state = new URLSearchParams(query.search).get('nop')

  const navigate = useNavigate()

  if (!state) navigate('/')

  const nop = useMemo(() => {
    const [kec, kel, blok, no, status] = (state as any).split('.')
    return [kec, kel, blok, no, status]
  }, [state])

  const {data, isLoading} = useQuery(
    'fetch-history-wajib-pajak-public',
    () => SpptSerice.publicSearchHistorySPPT(nop[0], nop[1], nop[2], nop[3], nop[4]),
    {
      refetchOnWindowFocus: false,
    }
  )

  const jumlahTagihan = useMemo(() => {
    if (data?.data) {
      console.log(data?.data)
      console.log(data?.data?.list)
      if (data?.data?.list.status === 'Fasilitas Umum') {
        return 0
      }

      return data?.data?.list?.reduce(
        (prev, next) => {
          if (next.status === 'belum dibayar') {
            return (typeof prev === 'number' ? prev : 0) + (next.jumlah_tagihan ?? 0)
          }
          return prev
        },
        [0]
      )
    }
    return 0
  }, [data])

  const totalDenda = useMemo(() => {
    if (data?.data) {
      if (data?.data?.list.status === 'Fasilitas Umum') {
        return 0
      }

      return data?.data?.list?.reduce(
        (prev, next) => {
          if (next.status === 'belum dibayar') {
            return (typeof prev === 'number' ? prev : 0) + (next.denda ?? 0)
          }
          return prev
        },
        [0]
      )
    }
    return 0
  }, [data])

  const showInformation = () => {
    if (
      data?.data?.information.no_sertifikat === null ||
      data?.data?.information.no_sertifikat === undefined
    ) {
      return (
        <>
          {isLoading ? 'Loading...' : data?.data?.information?.alamat},{' '}
          {data?.data?.information?.kelurahan}
        </>
      )
    } else {
      return (
        <>
          {isLoading ? 'Loading...' : data?.data?.information?.alamat},{' '}
          {data?.data?.information?.no_sertifikat}, {data?.data?.information?.kelurahan}
        </>
      )
    }
  }

  return (
    <Div className='text-center p-10 flex flex-col items-center'>
      <Link className='text-blue-500' to={'/'}>
        Beranda
      </Link>
      <Typography fontWeight={'600'} variant='h4'>
        Cek Pembayaran PBB-P2
      </Typography>
      <Typography variant='h5' className='mt-10'>
        {showInformation()}
      </Typography>
      <Typography variant='h5'>12.76.{state as any}</Typography>

      <Card className='border-[1px] md:w-[150vh] border-gray-300 mt-10 py-5 flex flex-col justify-between text-left'>
        <Div>
          {isLoading ? (
            <Div className='flex flex-col justify-center items-center my-10'>
              <CircularProgress />
              Memuat...
            </Div>
          ) : (
            <Div>
              <TableContainer>
                {data?.data?.list.status === 'Fasilitas Umum' ? (
                  <>
                    <Table sx={{}} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell className='p-2' align='center'>
                            No
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            Nama WP
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            NOP
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            Pokok PBB
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            Denda
                          </TableCell>
                          <TableCell className='p-2' align='right'>
                            Total Tagihan
                          </TableCell>
                          <TableCell className='p-2' align='right'>
                            Terbayar
                          </TableCell>

                          <TableCell className='p-2' align='center'>
                            Status
                          </TableCell>
                        </TableRow>
                        <TableRow key={2} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                          <TableCell className='p-2 text-center' component='th' scope='row'>
                            {1}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {data?.data?.list?.nama_wp ?? '-'}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {data?.data?.information?.nop ?? '-'}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {data?.data?.information?.pokok ?? '-'}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {data?.data?.information?.denda ?? '-'}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {data?.data?.information?.total_tagihan ?? '-'}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {data?.data?.information?.dibayar ?? '-'}
                          </TableCell>
                          <TableCell className='p-2 text-blue-600 capitalize' align='center'>
                            <Badge coloring={'primary'} className='text-white'>
                              {data?.data?.list.status}
                            </Badge>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </>
                ) : (
                  <Table sx={{}} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className='p-2' align='center'>
                          No
                        </TableCell>
                        <TableCell className='p-2' align='center'>
                          Nama WP
                        </TableCell>
                        <TableCell className='p-2' align='center'>
                          Tahun Pajak
                        </TableCell>
                        <TableCell className='p-2' align='center'>
                          Tanggal Jatuh Tempo
                        </TableCell>
                        <TableCell className='p-2' align='center'>
                          Tanggal Bayar
                        </TableCell>
                        <TableCell className='p-2' align='right'>
                          Pokok PBB
                        </TableCell>
                        <TableCell className='p-2' align='right'>
                          Denda
                        </TableCell>
                        <TableCell className='p-2' align='right'>
                          Jumlah Tagihan
                        </TableCell>
                        <TableCell className='p-2' align='center'>
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.data?.list?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          <TableCell className='p-2 text-center' component='th' scope='row'>
                            {index + 1}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {row.nama_wp}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {row.tahun_pajak}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {moment(row.tanggal_jatuh_tempo).format('DD-MM-YYYY') !== 'Invalid date'
                              ? moment(row.tanggal_jatuh_tempo).format('DD-MM-YYYY')
                              : '-'}
                          </TableCell>
                          <TableCell className='p-2' align='center'>
                            {moment(row.tanggal_dibayar).format('DD-MM-YYYY') !== 'Invalid date'
                              ? moment(row.tanggal_dibayar).format('DD-MM-YYYY')
                              : '-'}
                          </TableCell>
                          <TableCell className='p-2' align='right'>
                            {row.jumlah_tagihan?.toLocaleString('id-ID')}
                          </TableCell>
                          <TableCell className='p-2' align='right'>
                            {row.denda?.toLocaleString('id-ID')}
                          </TableCell>
                          <TableCell className='p-2' align='right'>
                            {(row.jumlah_tagihan + row.denda).toLocaleString('id-ID')}
                          </TableCell>
                          <TableCell className='p-2 text-blue-600 capitalize' align='center'>
                            <Badge
                              coloring={row.status === 'belum dibayar' ? 'danger' : 'primary'}
                              className='text-white'
                            >
                              {row.status}
                            </Badge>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell className='p-2' align='left' colSpan={5}>
                          <Typography className='text-red-700' fontWeight='bold'>
                            Jumlah Tunggakan PBB s/d tanggal {moment().format('L')}
                          </Typography>
                        </TableCell>
                        <TableCell className='p-2' align='right'>
                          <Typography className='text-red-700' fontWeight='bold'>
                            {(jumlahTagihan as number).toLocaleString('id-ID')}
                          </Typography>
                        </TableCell>
                        <TableCell className='p-2' align='right'>
                          <Typography className='text-red-700' fontWeight='bold'>
                            {(totalDenda as number).toLocaleString('id-ID')}
                          </Typography>
                        </TableCell>
                        <TableCell className='p-2' align='right'>
                          <Typography className='text-red-700' fontWeight='bold'>
                            {(jumlahTagihan + totalDenda).toLocaleString('id-ID')}
                          </Typography>
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Div>
          )}
        </Div>
      </Card>
      <div className='pt-[5vh] text-center md:mt-0 w-100 px-20'>
        <span className='text-gray-600 font-bold'>
          BPKPD (Badan Pengelolaan Keuangan Dan Pendapatan Daerah) KOTA BINJAI
        </span>
      </div>
    </Div>
  )
}

export default CekPembayaranPBB
