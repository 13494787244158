/* eslint-disable react/jsx-no-target-blank */
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon} from '../../../helpers'

const SidebarFooter = () => {
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      {/* <button
        onClick={() => {
          logout()
          navigate('/')
        }}
        className='btn btn-primary w-100 d-flex justify-content-center align-items-center'
      >
        <KTIcon iconName='exit-left' className='text-white' iconType='solid' />
        Sign Out
      </button> */}
    </div>
  )
}

export {SidebarFooter}
