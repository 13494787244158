import { AuthModel, Permission } from './_models'
import { showAlert } from '../../../helpers'
import { Buffer } from 'buffer'
import Swal from 'sweetalert2'
import { AxiosRequestConfig } from 'axios'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_LOCAL_STORAGE_PERMISSION_KEY = 'kt-auth-permission-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const getPermission = (): Permission[] | undefined => {
  const isValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_PERMISSION_KEY)

  if (!isValue) return

  try {
    const permission: Permission[] = JSON.parse(isValue)
    if (permission) return permission
  } catch (error) {
    console.error('AUTH PERMISSION LOCAL STROAGE PARSE ERRORR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setPermission = (permission: Permission[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(permission)
    localStorage.setItem(AUTH_LOCAL_STORAGE_PERMISSION_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removePermission = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_PERMISSION_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}



const showAlertChangePassword = () => {
  Swal.fire({
    icon: 'warning',
    showConfirmButton: true,
    confirmButtonText: 'Tutup',
    html: `
      Mohon secara berkala untuk mengganti password di menu <a class="text-blue-400 underline" href="/account/settings#setting-credentials">profile</a> demi keamanan data-data Bapak/Ibu sekalian, Terima Kasih
    `,
  })
}



export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const auth = getAuth()

      if (auth && auth.access_token) {
        config.headers!['Signature'] = Buffer.from(
          getAuth()?.user?.uuid_user ?? 'kk',
          'binary'
        ).toString('base64')
        config.headers!.Authorization = `Bearer ${auth.access_token}`
      }
      return config
    },
    (err: any) => {
      return Promise.reject(err)
    }
  )
  axios.interceptors.response.use(
    (res: any) => {
      if (res.config.url === '/auth/login' && res?.data?.user?.role === 'notaris') {
        showAlertChangePassword()
      }
      if (res?.data?.status === false) {
        // showAlert(res?.data?.message ?? res?.data, 'danger')
        showAlert('Gagal memproses data, silahkan coba lagi.', 'danger')
      }
      return res
    },
    (error: any) => {
      // let errMessage: string = error

      /**
       * Handling custom message for login
       */
      if (error?.response?.config?.url === '/auth/login') {
        Swal.fire({
          title: 'Gagal Login',
          text: 'Username dan Password salah, silahkan coba lagi.',
          icon: 'error',
          showCancelButton: false,
          reverseButtons: false,
          showConfirmButton: false,
        })
        return
      }

      if (error.response?.data) {
        if (error.response.data.message !== '' && error.response.data) {
          // errMessage = serilizeObjectToString(error.response.data.message ?? error.response.data)
        }
      }

      if (error.response?.status >= 500) {
        showAlert('Gagal memproses data, silahkan coba lagi.', 'danger')
      }

      if (error.response?.status === 401 && error.response.config.url !== '/auth/login') {
        removeAuth()
        document.location.reload()
        document.location = '/'
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export {
  getAuth,
  setAuth,
  removeAuth,
  AUTH_LOCAL_STORAGE_KEY,
  getPermission,
  setPermission,
  removePermission,
}
