import { IMenu } from "../_menu-config";

export const Laporan: IMenu[] = [
  {
    subItems: [
      {
        title: 'Laporan',
        guards: ['kecamatan', 'kelurahan'],
        fontIcon: 'duotone',
        icon: 'graph-4',
        link: 'applications/laporan/laporan-pbb',
      },
      {
        title: 'Laporan Notaris',
        guards: ['notaris'],
        fontIcon: 'duotone',
        guardSubBidang: ['pendataan & penetapan'],
        icon: 'graph-4',
        link: 'applications/laporan/laporan-notaris'
      },
    ],
  },
]
