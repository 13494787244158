import { IMenu } from "../_menu-config";

export const Realisasi: IMenu[] = [
  {
    subItems: [
      {
        title: 'Realisasi',
        guards: ['kecamatan', 'kelurahan'],
        link: 'applications/data-rekap-wajib-pajak/realisasi',
        fontIcon: 'duotone',
        icon: 'fasten',
      },
    ],
  },
]
