/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment } from 'react'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { DefaultConfig, IMenu } from '../../core/_menu-config'
import { AuthModel, useAuth } from '../../../../../app/modules/auth'
import LoopRenderer from 'src/app/widgets/molecule/loop-rendering'
import { ConditinalRendering } from 'src/app/widgets/molecule/conditional-rendering'
import IUserService from 'src/app/services/user/service'
import { SideBarMenuKabid } from './sidebar-menu-custom/kabid/Index'

interface CheckGuardProtectionParams {
  guards?: string[]
  guardBidang?: string[]
  guardSubBidang?: string[]
  title?: string
}



/**
 * checkGuardProtection.
 * @param {CheckGuardProtectionParams} param0 
 * @param {AuthModel} auth 
 * @returns {boolean}
 */
const checkGuardProtection = ({ guardBidang, guardSubBidang, guards, title }: CheckGuardProtectionParams, auth: AuthModel): boolean => {
  if (!guards) return true
  const role = auth.user.role

  if (title === 'Tagihan Kolektor') {
    if (auth.user.role === IUserService.ADMIN) {
      return auth.user.uuid_bidang !== null
    }
  }


  if (role === IUserService.ADMIN && !auth.user.nama_bidang) {
    if (guards.includes(role) && !auth.user.uuid_bidang) {
      return guardBidang?.includes('admin_bidang_empty')
    }
  }


  if (guardSubBidang && auth.user.nama_sub_bidang) {
    const result = guardSubBidang.includes(auth.user.nama_sub_bidang.toLowerCase())
    if (guards) {
      return result && guards.includes(role)
    }
    return guardSubBidang.includes(auth.user.nama_sub_bidang.toLowerCase())
  }

  if (guardBidang && auth.user.nama_bidang) {
    return guardBidang.includes(auth.user.nama_bidang.toLowerCase())
  }

  return guards.includes(role)
}



/**
 * Configurate navigate and setting show by guards
 *
 * @returns {React.ReactNode}
 */
const SidebarMenuMain: FC = () => {
  const { auth } = useAuth()
  let menu: IMenu[] = DefaultConfig


  switch (auth!.user!.role) {
    case IUserService.KABID:
      return <SideBarMenuKabid />
    default:
      return (
        <Fragment>
          {/* do looping menu list */}
          <LoopRenderer data={menu}>
            {(item: IMenu, index) => (
              <Fragment key={crypto.randomUUID()}>
                {/* conditional for content guard menu */}
                <ConditinalRendering
                  conditional={checkGuardProtection({
                    guards: item.guards,
                    guardBidang: item.guardBidang,
                    guardSubBidang: item.guardSubBidang,
                    title: item.title
                  }, auth)}
                  onTrueComponent={
                    <Fragment>
                      {/* parent title */}
                      <ConditinalRendering
                        conditional={item.title !== undefined}
                        onTrueComponent={
                          <Fragment>
                            <div className='menu-item'>
                              <div className='menu-content pt-8 pb-2'>
                                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                  {item.title}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                        }
                      />
                    </Fragment>
                  }
                />
                {/* first menu */}
                <ConditinalRendering
                  conditional={checkGuardProtection({
                    guards: item.guards,
                    guardBidang: item.guardBidang,
                    guardSubBidang: item.guardSubBidang,
                    title: item.title
                  }, auth)}
                  onTrueComponent={
                    <LoopRenderer data={item.subItems}>
                      {(subMenu: IMenu, index: number) => (
                        <Fragment>
                          {/* second menu */}
                          <ConditinalRendering
                            conditional={
                              subMenu.subItems !== undefined &&
                              checkGuardProtection({
                                title: subMenu.title,
                                guards: subMenu.guards,
                                guardBidang: subMenu.guardBidang,
                                guardSubBidang: subMenu.guardSubBidang,
                              }, auth)
                            }
                            onTrueComponent={
                              <SidebarMenuItemWithSub
                                key={crypto.randomUUID()}
                                to={subMenu.link!}
                                title={subMenu.title!}
                                fontIcon={subMenu.fontIcon}
                                icon={subMenu.icon}
                                isVisible={subMenu.isVisible}
                              >
                                <LoopRenderer data={subMenu.subItems}>
                                  {(optionMenu: IMenu, _) => (
                                    <SidebarMenuItem
                                      key={crypto.randomUUID()}
                                      to={optionMenu.link ?? ''}
                                      title={optionMenu.title ?? ''}
                                      hasBullet={true}
                                    />
                                  )}
                                </LoopRenderer>
                              </SidebarMenuItemWithSub>
                            }
                            onFalseComponent={
                              <ConditinalRendering
                                conditional={checkGuardProtection({
                                  title: subMenu.title,
                                  guards: subMenu.guards,
                                  guardBidang: subMenu.guardBidang,
                                  guardSubBidang: subMenu.guardSubBidang,
                                }, auth)}
                                onTrueComponent={
                                  <SidebarMenuItem
                                    key={crypto.randomUUID()}
                                    to={subMenu.link!}
                                    icon={subMenu.icon}
                                    title={
                                      subMenu.calculation !== undefined
                                        ? subMenu.calculation!(auth?.user.name ?? '')
                                        : subMenu.title ?? ''
                                    }
                                    fontIcon={subMenu.fontIcon}
                                  />
                                }
                              />
                            }
                          />
                        </Fragment>
                      )}
                    </LoopRenderer>
                  }
                />
              </Fragment>
            )}
          </LoopRenderer>
        </Fragment>
      )
  }
}

export { SidebarMenuMain }
