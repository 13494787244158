import {MenuItem} from './MenuItem'
import {DefautlConfigHeader} from '../../core/_menu-config'
import {useAuth} from '../../../../../app/modules/auth'
import IUserService from 'src/app/services/user/service'


interface CheckGuardProtectionParams {
  guards?: string[]
  guardBidang?: string[]
  guardSubBidang?: string[]
}


export function MenuInner() {
  const {auth} = useAuth()

  const checkGuardProtection = ({ guardBidang, guardSubBidang, guards }: CheckGuardProtectionParams): boolean => {
    
    if (!guards) return true
    const role = auth.user.role

    if (guardSubBidang && auth.user.nama_sub_bidang && auth.user.role !== IUserService.KASUBBID) {
      return guardSubBidang.includes(auth.user.nama_sub_bidang.toLowerCase())
    }

    if (guardBidang && auth.user.nama_bidang) {
      return guardBidang.includes(auth.user.nama_bidang.toLowerCase())
    }

    return guards.includes(role)
  }

  return (
    <>
      {DefautlConfigHeader.map((data) => {
        return (
          checkGuardProtection({ guards: data.guards, guardBidang: data.guardBidang, guardSubBidang: data.guardSubBidang }) && (
            <MenuItem title={data.title!} to={data.link!} key={crypto.randomUUID()} />
          )
        )
      })}
    </>
  )
}
