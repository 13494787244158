import axios from "axios";
import { RegisterNotarisParams } from "./type";
import { QueryParams, ResMessage } from "src/app/types/api";
import { objectToFormData } from "src/app/helpers";

export class Authentication {


  /**
   * Register Notaris.
   * This method usage for register just user notaris
   * 
   * @param {RegisterNotarisParams} params 
   * @param {QueryParams | null} queryParams
   * @returns {ResMessage<string>}
   */
  public static registerNotaris = (params: RegisterNotarisParams, queryParams?: QueryParams): ResMessage<string> => {
    const endpoint: string = 'auth/register/notaris'
    const formData: FormData = objectToFormData(params)

    return axios.post(endpoint, formData, { params: queryParams }).then(res => res.data)
  }

} 