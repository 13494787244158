import { IMenu } from "../_menu-config";

export const Settings: IMenu[] = [
  {
    title: 'Pengaturan',
    guards: ['superadmin'],
    subItems: [
      {
        title: 'Pengaturan',
        link: 'settings',
        icon: 'setting-3',
        fontIcon: 'duotone',
        subItems: [
          {
            title: 'Backup Database',
            icon: 'frame',
            fontIcon: 'duotone',
            link: '#'
          },
          {
            title: 'Banner',
            icon: 'file',
            fontIcon: 'duotone',
            link: 'settings/banners',
          },
          {
            title: 'Menu',
            icon: 'menu',
            fontIcon: 'duotone',
            link: 'settings/menus',
          },
          {
            title: 'Layouts',
            link: 'settings/layouts',
          },
        ],
      },
    ],
  },
]
