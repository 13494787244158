/* eslint-disable react-hooks/exhaustive-deps */
import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {Logout} from '../modules/auth'

const PrivateRoutes = () => {
  const DashbaordPage = lazy(() => import('../modules/dashboard/index'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const TransactionPage = lazy(() => import('../modules/transaksi/index'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/Index'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const PelayananPage = lazy(() => import('../modules/old_pelayanan/Index'))
  const PelayananNewPage = lazy(() => import('../modules/pelayanan/App'))
  const MasterDataPage = lazy(() => import('../modules/master-data'))
  const ApplicationPage = lazy(() => import('../modules/applications/ApplicationPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const SettingPage = lazy(() => import('../modules/settings/Index'))
  const LapanganPage = lazy(() => import('../modules/lapangan/index'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='dashboard'
          element={
            <SuspensedView>
              <DashbaordPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='pelayanan/*'
          element={
            <SuspensedView>
              <PelayananPage />
            </SuspensedView>
          }
        />
        <Route
          path='pelayanan/v2/*'
          element={
            <SuspensedView>
              <PelayananNewPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='transaction/*'
          element={
            <SuspensedView>
              <TransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='master-data/*'
          element={
            <SuspensedView>
              <MasterDataPage />
            </SuspensedView>
          }
        />
        <Route
          path='applications/*'
          element={
            <Suspense>
              <ApplicationPage />
            </Suspense>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />
        <Route
          path='lapangan/*'
          element={
            <SuspensedView>
              <LapanganPage />
            </SuspensedView>
          }
        />
        <Route path='sign-auth' element={<Logout />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dashboard' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
