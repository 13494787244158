/* eslint-disable react-hooks/exhaustive-deps */
import {ErrorMessage, Field, FieldProps} from 'formik'
import {FC, Fragment, useCallback, useMemo, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import {ImagePreview} from '../molecule/image-preview'
import {Image} from 'react-bootstrap'
import InputMask from 'react-input-mask'
import {PdfPreviewInput} from 'src/app/widgets/molecule/pdf-preview-input'
import clsx from 'clsx'
import './scss/input.scss'
import Swal from 'sweetalert2'
import {title} from 'process'

/**
 * This is wrapping input field and styling wrapping
 *
 * @param {EFormType} props - params for EForm get form EFormType
 * @returns {React.ReactNode}
 */
const EForm: FC<EFormType> = ({
  as,
  name,
  showError,
  options,
  value,
  src,
  label,
  subLabel,
  classNameLabel,
  onChange,
  mask,
  placeholder,
  withLabel = true,
  className,
  ...props
}) => {
  const [filePreview, setfilePreview] = useState<string | null>(null)
  const [fileType, setFileType] = useState<string | null>(null)
  const {customformat, ...reprops} = props
  const onKeyup = useMemo(() => {
    if (props.customformat) {
      return (event) => {
        event.target.value = props.customformat(event.target.value)
      }
    }
    return null
  }, [])

  const validateFile = (file: File) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
    const maxSize = 20 * 1024 * 1024 //20MB
    if (!allowedTypes.includes(file.type)) {
      return Swal.fire({title: 'file tidak valid gunakan jpg, jpeg, png', icon: 'info'})
    }
    if (file.size > maxSize) {
      return Swal.fire({title: `Ukuran file tidak boleh lebih dari ${maxSize} bytes`, icon: 'info'})
    }
    return null
  }

  // console.log(as)
  /**
   * Handling file change.
   *
   * @param event
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0]
    const type = event.target.files![0].type

    const error = validateFile(file)
    if (error) {
      return
    }

    setFileType(type)
    setfilePreview(URL.createObjectURL(file))
  }

  const id = crypto.randomUUID().split('-')[0]
  return (
    <div {...reprops}>
      {label && withLabel && (
        <div className='mb-1'>
          <label
            className={
              classNameLabel ?? 'form-label text-capitalize m-0 d-flex justify-content-start'
            }
            htmlFor={`${name}-${id}`}
          >
            {label}
          </label>
          {subLabel && (
            <label className='text-sm align-text-bottom font-semibold text-gray-600'>
              {subLabel}
            </label>
          )}
        </div>
      )}

      {/* Handle for text or password input type */}
      {['text', 'password', 'number'].includes(as) && (
        <Field name={name} value={value}>
          {({field, form}: any) => {
            if (onKeyup !== null) {
              field.value = props.customformat(field.value)
            }

            // console.log(field.value)

            if (mask) {
              return (
                <InputMask
                  mask={mask}
                  name={name}
                  onKeyUp={onKeyup}
                  {...field}
                  value={field.value}
                  onChange={(e) => {
                    form.setFieldValue(field.name, e.target.value)
                    if (onChange) onChange(e)
                  }}
                  placeholder={placeholder ? placeholder : label ? `Masukan ${label}` : ''}
                  className={clsx('eform--input--control', {[className]: className})}
                />
              )
            }

            return (
              <input
                type={as}
                name={name}
                {...field}
                placeholder={placeholder === undefined ? `Masukan ${label}` : placeholder}
                className={clsx('eform--input--control', {[className]: className})}
                {...(onChange !== undefined ? {onChange: onChange} : '')}
              />
            )
          }}
        </Field>
      )}

      {as === 'textarea' && (
        <Field
          as='textarea'
          name={name}
          className='eform--input--control'
          rows={props.rows ?? 3}
        ></Field>
      )}

      {as === 'readonly' && (
        <Field name={name} value={value}>
          {({field, form}: any) => {
            return (
              <input
                {...field}
                value={form?.values[name] ?? value}
                type='text'
                readOnly
                className={clsx('eform--input--control', {[className]: className})}
              />
            )
          }}
        </Field>
      )}

      {as === 'hidden' && (
        <Field as='text' name={name} value={value} className='hidden'>
          {({field, form}: FieldProps) => {
            if (value) form.setFieldValue(field.name, value)
            return <input {...field} />
          }}
        </Field>
      )}

      {/* handle for date type */}
      {as === 'date' && (
        <Field name={name} value={value ?? ''}>
          {({field, form}: any) => {
            return (
              <Fragment>
                <input
                  type='date'
                  {...field}
                  onChange={(e) => {
                    form.setFieldValue(field.name, e.target.value)
                    if (onChange) onChange(e)
                  }}
                  className='eform--input--control'
                />
              </Fragment>
            )
          }}
        </Field>
      )}

      {/* Handle for select type */}
      {as === 'select' && (
        <Field name={name}>
          {({field, form}: any) => {
            return (
              <Fragment>
                <select
                  {...field}
                  onChange={(e) => {
                    form.setFieldValue(field.name, e.target.value)
                    if (onChange) onChange(e)
                  }}
                  className='form-select form-select-lg form-select-solid text-base'
                >
                  <option value='' hidden>{`Pilih ${label}`}</option>
                  {!options && (
                    <option className='text-center' key={crypto.randomUUID()} value=''>
                      Data Tidak Ditemukan
                    </option>
                  )}
                  {options?.map((d) => (
                    <option key={crypto.randomUUID()} value={d.value}>
                      {d.label}
                    </option>
                  ))}
                </select>
              </Fragment>
            )
          }}
        </Field>
      )}

      {/* Handle for image */}
      {as === 'file' && (
        <Field type='file' name={name}>
          {({field, form}: any) => {
            console.log(form)
            return (
              <Fragment>
                <div
                  className='image-input rounded p-5 image-input-outline image-input-placeholder shadow mb-3 image-input-empty'
                  style={{
                    width: 'auto !important',
                  }}
                >
                  <label
                    htmlFor={`${name}-${id}`}
                    className='position-absolute  btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    style={{
                      right: '-13px',
                      top: '-13px',
                      zIndex: 1000,
                    }}
                  >
                    <KTIcon iconName='pencil' />
                    <input
                      onChange={(e) => {
                        const file = e.currentTarget.files![0]
                        handleFileChange(e)
                        form.setFieldValue(field.name, file)
                      }}
                      accept='image/*,.pdf'
                      className='d-none'
                      type='file'
                      name={name}
                      id={`${name}-${id}`}
                    />
                  </label>
                  {filePreview && (
                    <label
                      className='position-absolute  btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      style={{
                        bottom: '-13px',
                        right: '-13px',
                      }}
                      onClick={() => {
                        form.setFieldValue(field.name, '')
                        setfilePreview(null)
                      }}
                    >
                      <KTIcon iconName='cross' />
                    </label>
                  )}
                  <div
                    style={{
                      width: 'auto !important ',
                    }}
                  >
                    {filePreview ? (
                      <Fragment>
                        {fileType !== 'application/pdf' ? (
                          <ImagePreview
                            className={`object-fit-cover ${className}`}
                            width={200}
                            url={filePreview}
                            name={name}
                          />
                        ) : (
                          <PdfPreviewInput
                            className={`object-fit-cover ${className}`}
                            width={200}
                            url={filePreview}
                            name={name}
                          />
                        )}
                      </Fragment>
                    ) : (
                      <label className='cursor-pointer' htmlFor={`${name}-${id}`}>
                        {src ? (
                          <Image className={`object-fit-cover ${className}`} src={src} />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl('/media/svg/files/folder-document.svg')}
                            className='w-[80px] m-6 h-[80px]'
                          />
                        )}
                      </label>
                    )}
                  </div>
                </div>
              </Fragment>
            )
          }}
        </Field>
      )}

      {/* Error Message */}
      <div
        className={clsx('text-danger mt-2', {
          hidden: as === 'hidden',
        })}
      >
        {showError ? (
          <ErrorMessage name={name} />
        ) : (
          <Fragment>{showError ?? <ErrorMessage name={name} />}</Fragment>
        )}
      </div>
    </div>
  )
}

export {EForm}
