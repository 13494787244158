import axios from 'axios'
import {AuthModel} from './_models'

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>('/auth/login', {
    username,
    password,
  })

  // return axios.get<AuthModel>('/login')
}

// Do logout
export function signOut() {
  return axios.post('/auth/logout')
}
