/* eslint-disable @typescript-eslint/no-unused-expressions */
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "src/app/helpers/index";
import { FC, forwardRef } from "react";

const badgeVariants = cva(
  'text-sm',
  {
    variants: {
      variant: {
        default: 'py-1 px-2 rounded-xl ',
        dot: ''
      },
      coloring: {
        primary: 'bg-blue-400',
        warning: 'bg-orange-400',
        danger: 'bg-red-500',
        success: 'bg-green-400'
      }
    },
    defaultVariants: {
      variant: 'default',
      coloring: 'success'
    }
  }
);

export interface BadgeProps
  extends React.LabelHTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof badgeVariants> {}

export const Badge: FC<BadgeProps> = forwardRef<HTMLSpanElement, BadgeProps>(
  ({ variant, coloring, className, children, ...props }, ref) => {
    let classNameBuild: string = '';

    if (variant === 'dot') {
      classNameBuild = 'flex items-center text-sm capitalize'
    } else {
      classNameBuild = cn(badgeVariants({ variant, coloring }), className)
    }

    return (
      <span ref={ref} className={classNameBuild} {...props}>
        {variant === 'dot' && <div className={`w-[10px] h-[10px] rounded-full me-2 ${badgeVariants({ coloring, variant })}`}></div>}
        {children}
      </span>
    )
  }
);
