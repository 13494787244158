import {FC, memo, useEffect, useState} from 'react'
import {Card, ProgressBar, Row, Spinner} from 'react-bootstrap'
import {RealisasiTerbaru} from 'src/app/services/realisasi/type'
import Headline from 'src/app/widgets/atomic/headline'
import Text from 'src/app/widgets/atomic/text'
import LoopRenderer from 'src/app/widgets/molecule/loop-rendering'
import {Devider} from 'src/app/widgets/atomic/devider'
import {ConditinalRendering} from 'src/app/widgets/molecule/conditional-rendering'
import { CircularProgress } from '@mui/material'

/**
 * type queue realisasi component
 */
interface QueueRealisasiProps {
  data: RealisasiTerbaru[]
  totalHariIni: number
  totalBulanIni: number
  totalSemua: number
  isFeching: boolean
}

/**
 * Queue Realisasi Component
 *
 * This component usage for handling listing news latest transaction
 * in pajak
 * @returns {React.ReactElement}
 */
export const QueueRealisasi: FC<QueueRealisasiProps> = memo(
  ({data, totalBulanIni, totalHariIni, totalSemua, isFeching}) => {
    const [stateloading, setstateloading] = useState(false)

    useEffect(() => {
      setstateloading(true)
    }, [])


    useEffect(() => {
      if (isFeching === true) setstateloading(false)
    }, [isFeching])


    return (
      <div className='w-100'>
        <Card className='border-none sm:w-100 mb-2 shadow-md p-5 overflow-hidden'>
          {
            stateloading === false
              ? (
                  <Row md={2}>
                    <span className='text-base font-bold'>Total Tahun Ini</span>
                    <span className='text-right font-bold text-green-800'>
                      Rp {totalSemua?.toLocaleString('id-ID')}
                    </span>
                    <span className='text-base font-bold'>Total Bulan Ini</span>
                    <span className='text-right font-bold text-green-800'>
                      Rp {totalBulanIni?.toLocaleString('id-ID')}
                    </span>
                    <span className='text-base font-bold'>Total Hari Ini</span>
                    <span className='text-right font-bold text-green-800'>
                      Rp {totalHariIni?.toLocaleString('id-ID')}
                    </span>
                  </Row>
                )
              : (
                <div className="flex justify-center items-center">
                  <Spinner />
                </div>
              )
          }
        </Card>
        <Card className='border-none bg-gray-200 sm:w-100 h-[57vh] shadow-md p-5 overflow-hidden'>
          {
            stateloading === false
              ? (
                <>
                  <Headline className='text-xl'>Transaksi Hari Ini</Headline>
                  <Devider className='text-white' />
                  <div className='max-h-[55vh] overflow-y-scroll mt-5'>
                    <ConditinalRendering
                      conditional={data.length > 0}
                      onTrueComponent={
                        <LoopRenderer loopCount={1} data={data}>
                          {(item: RealisasiTerbaru, index: number) => (
                            <div className='border-b-2 py-2 flex justify-between'>
                              <div className='flex justify-between'>
                                <Text className='text-base'>{item.nama ?? ''}</Text>
                              </div>
                              <Text className='text-base align-text-bottom font-bold text-green-700'>
                                Rp {(item.nominal as number).toLocaleString('id-ID')}
                              </Text>
                            </div>
                          )}
                        </LoopRenderer>
                      }
                      onFalseComponent={
                        <div className='flex justify-center items-center h-100'>
                          <span className='font-bold '>Belum Ada Data</span>
                        </div>
                      }
                    />
                  </div>
                </>
              )
            : (
              <div className="flex h-100 w-100 justify-center items-center">
                <Spinner />
              </div>
            )
          }
        </Card>
      </div>
    )
  }
)
