import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  badge?: string
  rightText?: string
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  badge,
  rightText,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  let newTitle = title.split('\n')

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <div className='flex flex-col w-100'>
          <span className='menu-title text-capitalize'>{newTitle[0]}</span>
          {newTitle[1] && <span className='menu-title text-sm text-capitalize'>{newTitle[1]}</span>}
        </div>
        {rightText && (
          <span
            data-kt-menu-span={rightText ? true : undefined}
            data-kt-menu-span-kode={rightText}
            className='text-white bg- w-[22px] h-[17px] pb-[1px] bg-[#2a2a3c] rounded-full flex justify-center items-center'
          >
            0
          </span>
        )}
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
