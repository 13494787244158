import {ResponseServerWithMessage} from 'src/app/types/api'
import {RealisasiRespone} from './type'
import axios from 'axios'

/**
 * Interface or abstracction for realisasi service
 */
export interface IRealisasi {
  listing(queryParams?: string): Promise<ResponseServerWithMessage<RealisasiRespone>>
}

/**
 * Realisasi Service
 *
 * This lass will handling all about realisasi action like call data
 * from remote data store
 */
export class RealisasiService implements IRealisasi {
  listing(queryParams?: string | undefined): Promise<ResponseServerWithMessage<RealisasiRespone>> {
    const endpoint: string = 'public/realisasi'

    return axios.get(endpoint).then((res) => res.data)
  }
}
