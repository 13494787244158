import { RegisterNotarisParams } from 'src/app/services/authentication/type'
import * as Yup from 'yup'


const phoneNumberValidate = (value: string): boolean => {
  try {
    const numberValidLength: number[] = [12, 13, 14]
    let cleanNumber: string = value.split('-').join('')
    cleanNumber = cleanNumber.replace(/_/g, '')

    return numberValidLength.includes(cleanNumber.length)
  } catch (_) {
    return false
  }
} 


export const validator = Yup.object({
  name: Yup.string().required().label('Nama'),
  password: Yup.string().required().label('Password'),
  alamat: Yup.string().required().label('Alamat'),
  no_hp: Yup.string()
            .test('numberRight', 'Nomor HP salah', phoneNumberValidate)
            .label('Nomor Telepon'),
  kota: Yup.string().required().label('Kota')
})

export type ValidatorType = Yup.InferType<typeof validator> & RegisterNotarisParams

export const validatorInit: ValidatorType = {
  alamat: '',
  kota: '',
  name: '',
  no_hp: '',
  password: ''
}