import {FC, Fragment} from 'react'
import {Outlet} from 'react-router-dom'

export const Footer: FC = () => {
  return (
    <Fragment>
      <Outlet />
      <div className=' mt-[70vh] text-center md:mt-0 w-100 px-20'>
        <span className='text-gray-600 font-bold'>BPKPD (Badan Pengelolaan Keuangan Dan Pendapatan Daerah) KOTA BINJAI</span>
      </div>
    </Fragment>
  )
}
