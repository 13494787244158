// atoms/Div.tsx
import React, { HTMLProps } from 'react';

interface DivProps extends HTMLProps<HTMLDivElement> {
  // Add any custom props you want here
  customProp?: string;
}

const Div: React.FC<DivProps> = ({ children, customProp, ...rest }) => {
  return <div {...rest}>{children}</div>;
};

export { type DivProps }
export default Div;