import { Menu, RestructMenu } from '../services/setting/type'

export class StringHelpers {
  /**
   * This component usage for handling
   *
   * @param {string} value - Value string
   * @returns {React.ReactElement}
   */
  public static formatInputToNumber = (value: string) => {
    try {
      let getNumber: any = value.match(/\d+/g)
      getNumber = getNumber !== null ? getNumber.join('') : '0'

      return getNumber
    } catch {
      return '0'
    }
  }

  /**
     * removeSpecialCharacter.
     * This method usage for remove special character 
     * Example
     * Rp. 1.000.000 to 1000000
     * Output: Number
     *
     * @param {Object} objectValue
     * @param {string[]} keys
     * @returns {any}
     */
  public static removeSpecialCharacter = (value: string) => {

    if (value == "0" || value == null || value == undefined || typeof value === 'number') {
      return value
    }
    const format = value.replace(/[^0-9,-]+/g, '')
    const convertStringToNumber = parseFloat(format)
    return convertStringToNumber
  }

  /**
   * getValueDeepObject.
   * This method usage for get value from object by keys
   *
   * @param {Object} objectValue
   * @param {string[]} keys
   * @returns {any}
   */
  public static getValueDeepObject = (objectValue: Object, ...keys: string[]): any => {
    return keys.reduce((prev, curr) => (prev ? prev[curr] : null), objectValue)
  }

  public static onlyInputNumber = (value: string) => {
    return value.replace(/[^0-9]/g, '')
  }

  /**
   * findIndexObjectFromArray
   * This method will find object with specifict valeu by key in array
   * and will return index
   *
   * @param {Object[]} objects
   * @param {string | number} valueCompare
   * @param {string[]} keys
   * @returns {number}
   */
  public static findIndexObjectFromArray = (
    objects: Object[],
    valueCompare: string | number,
    ...keys: string[]
  ): number => {
    return objects.findIndex((value) => this.getValueDeepObject(value, ...keys) === valueCompare)
  }


  /**
   * transformDataMenuList
   * This method usage for handling restruct from array struc to object tree
   * struct.
   * 
   * @param {Menu[]} inputData 
   * @returns {RestructMenu[]}
   */
  public static transformDataMenuList = (inputData: Menu[]): RestructMenu[] => {
    const result: RestructMenu[] = []
    const itemMap: Record<string, RestructMenu> = {}

    inputData.forEach((item) => {
      const linkParts = item.link.split('/').filter((part) => part !== '')
      let currentLink = ''

      linkParts.forEach((part, index) => {
        currentLink += `/${part}`
        let existingSubMenu = itemMap[currentLink]

        if (!existingSubMenu) {
          const newSubMenu: RestructMenu = {
            title: index === linkParts.length - 1 ? item.nama_menu : '',
            link: currentLink,
            id: item.uuid_menu,
            parent_id: item.is_main_menu ?? '',
            subItems: [],
            icon: item.icon ?? ''
          }

          if (index > 0) {
            const parentLink = `/${linkParts.slice(0, index).join('/')}`
            existingSubMenu = itemMap[parentLink]
            existingSubMenu.subItems.push(newSubMenu)
          } else {
            result.push(newSubMenu)
          }

          itemMap[currentLink] = newSubMenu
        } else {
          if (existingSubMenu.link === item.link) {
            existingSubMenu.icon = existingSubMenu.icon !== '' ? existingSubMenu.icon : item.icon
            existingSubMenu.id = existingSubMenu.id !== '' ? existingSubMenu.id : item.uuid_menu
            existingSubMenu.parent_id = item.is_main_menu ?? existingSubMenu.parent_id
            existingSubMenu.title = existingSubMenu.title !== '' ? existingSubMenu.title : item.nama_menu
          }
        }
      })
    })

    return result
  }



  /**
   * This method will remove value by object.
   * 
   * @param {Object} objectValue 
   * @param {string[]} keys 
   * @returns {Object}
   */
  public static removeValueObject = (objectValue: Object, ...keys: string[]): Object => {
    let result = objectValue

    for (const key of keys) {
      try {
        delete result[key]
      } catch (_) { }
    }

    return result
  }


  /**
   * toCapitalize.
   * This method usage for capitalize eact word inside sentence.
   * 
   * @param {string} value 
   * @returns {string}
   */
  public static toCapitalize = (value: string): string => {
    try {
      value = value.toLocaleLowerCase()
      return value.split(" ").reduce((prev, curr) => `${prev} ${curr.charAt(0).toUpperCase() + curr.slice(1)}`, '')
    } catch {
      return ''
    }
  }



  /**
   * getNextAlphabertChar
   * This method usage for get next char from params.
   * 
   * @param {string} char 
   * @returns {string}
   */
  public static getNextAlphabetChar = (char: string): string => {
    if (typeof char !== 'string' || char.length !== 1 || !/[a-zA-Z]/.test(char)) {
      throw new Error('Input harus berupa satu karakter alfabet.');
    }

    const isUpperCase = char === char.toUpperCase();
    const currentCharCode = char.toUpperCase().charCodeAt(0);
    const nextCharCode = isUpperCase ? currentCharCode + 1 : currentCharCode + 1 + 32; // 32 is the difference between uppercase and lowercase ASCII codes

    // Check for overflow beyond 'Z' or 'z'
    if ((isUpperCase && nextCharCode > 'Z'.charCodeAt(0)) || (!isUpperCase && nextCharCode > 'z'.charCodeAt(0))) {
      return isUpperCase ? 'A' : 'a';
    }

    return String.fromCharCode(nextCharCode);
  }


  /**
   * removeSpecialCharacter
   * This method usage for remove special character from string
   * example 
   * 12.76.050.002.012.0023.0  to 12760500020120023
   * @param {string} value
*/
  public static removeSpecialCharacterString = (value?: string): string => {
    console.log(value)
    if (value === undefined || value === null || value === '') {
      return value
    }
    const formatString = value?.replace(/[^0-9,-]+/g, '')
    return formatString;
  }



  /**
   * errorMessageRebuild.
   * This function will rebuild object message validation to string with `\n`
   * for entering from message
   * @param {Object} msg 
   * @returns {string}
   */
  public static errorMessageRebuild = (msg: Object): string => {
    const valuesMessage = Object.values(msg)
    return valuesMessage.reduce((prev, next) => {
      if (prev !== null) {
        return `${prev}\n${next[0]}`
      }
      return next[0]
    })
  }



  /**
   * formatRupiah
   * format from string number to to format indonesia currency.
   * example:
   * 10000 -> Rp. 10.000
   * @param {string} angka 
   * @param {string} prefix 
   * @returns {string}
   */
  public static formatRupiah = (angka: string | number, prefix: string = 'Rp. '): string => {
    if (typeof angka === 'number') {
      angka = angka.toString()
    }
    try {
      let number_string = angka.replace(/[^,\d]/g, '').toString(),
        split = number_string.split(','),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi),
        separator = ''

      if (ribuan) {
        separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }

      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
      return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    } catch {
      return ''
    }
  }

  /**
 * Format number
 * format from string number to to format indonesia.
 * example:
 * 10000 -> 10.000
 * @param {string} angka 
 * @param {string} prefix 
 * @returns {string}
 */

  public static formatNumber = (
    angka: number = 0,
    format: 'decimal' | 'currency' | 'percent' = 'decimal',
    minimumFractionDigits: number = 0,
  ): string => {
    const options: Intl.NumberFormatOptions = {
      style: format,
      minimumFractionDigits: minimumFractionDigits,
    };


    const formatter = new Intl.NumberFormat('id-ID', options);
    return formatter.format(angka);
  }

  /**
   * Format NOP
   * format from string number to Nop
   * example:
   * 12.76.040.004.001.0053.0
   * @param {string} angka 
   * @param {string} prefix 
   * @returns {string}
   */
  public static formatNOP = (nop: string | number): string => {
    const NOP = nop.toString()
    // 12.76.040.004.001.0053.0

    const pattern = [2, 2, 3, 3, 4, 1];

    // console.log(NOP.slice(0, 10))
    let nop1 = NOP.slice(0, -16)
    let nop2 = NOP.slice(2, -14)
    let nop3 = NOP.slice(4, -11)
    let nop4 = NOP.slice(7, -8)
    let nop5 = NOP.slice(10, -5)
    let nop6 = NOP.slice(13, -1)
    let nop7 = NOP.slice(17)

    let result: string = `${nop1}.${nop2}.${nop3}.${nop4}.${nop5}.${nop6}.${nop7}`

    return result

  }



  /**
   * formatPersentance.
   * Usage for format input with prefix persentance
   * @param {string | number} angka 
   * @returns {string}
   */
  public static formatPersentance = (angka: string | number): string => {
    try {
      let numberString

      if (typeof angka === 'string') numberString = angka.replace(/\D+/g, '').toString()
      else numberString = angka.toString().replace(/\D+/g, '').toString()

      return numberString + '%'
    } catch (e) {
      return ''
    }
  }

  /**
  * Divided NJOP and luas.
  * Get unit prices per meter for land and buildings
  * using the formula NJOP/luas_bangunan or NJOP / luas_bumi
  * @param {string | number} angka 
  * @returns {string}
  */
  public static operationDivided = (njop: string | number, luas: string | number, convert?: boolean): string => {
    try {
      const NJOP = Number(njop)
      const LUAS = Number(luas)

      if (LUAS == 0 || NJOP == 0) return '0'

      const calculate = Math.ceil(!Number.isNaN(NJOP / LUAS) ? NJOP / LUAS : 0)
      const result = calculate.toString()
      if (convert) {
        const convert = this.formatRupiah(result)
        return convert
      }
      return result

    } catch (e) {
      return ''
    }
  }
}
