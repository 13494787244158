/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import {FC, Fragment, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from 'src/_metronic/helpers'

export type PdfPreviewInputProps = {
  name: string
  url?: string
  [key: string]: any
}

export const PdfPreviewInput: FC<PdfPreviewInputProps> = ({url, name, className, style}) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <Fragment>
      <div style={style} className={`card overlay overflow-hidden ${className}`}>
        <div onClick={() => setShowModal(true)} className='card-body p-0'>
          <div className='overlay-wrapper w-100 h-100'>
            <iframe src={url} className='w-100 h-100'></iframe>
          </div>
          <div className='overlay-layer bg-dark bg-opacity-25'>
            <span className='text-white badge badge-primary cursor-pointer text-capitalize'>
              {name}
            </span>
          </div>
        </div>
      </div>
      <Modal
        size='lg'
        onHide={() => setShowModal(false)}
        show={showModal}
        backdrop={'static'}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title className='text-capitalize'>{name}</Modal.Title>
          <div onClick={() => setShowModal(false)}>
            <KTIcon
              iconName='cross-square'
              className='text-4xl cursor-pointer hover:text-blue-400'
            />
          </div>
        </Modal.Header>
        <Modal.Body className='h-[80vh]'>
          <iframe src={url} className='w-100 h-100'></iframe>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
