import { ADJUSTMENT_SERVICE_MENU } from "../_data";
import { IMenu } from "../_menu-config";

export const Transaksi: IMenu[] = [
  {
    guards: ['superadmin', 'admin', 'notaris', 'kasubbid', 'kabid'],
    guardSubBidang: ['pendataan & penetapan'],
    adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
    link: 'transaction',
    subItems: [
      {
        title: 'SSPD',
        fontIcon: 'duotone',
        icon: 'financial-schedule',
        link: 'transaction/sspd',
        guards: ['superadmin', 'admin', 'kabid', 'kasubbid'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        guardSubBidang: ['pendataan & penetapan'],
        subItems: [
          {
            title: 'Semua SSPD',
            link: 'transaction/sspd/daftar-sppd',
          },
          {
            title: 'SSPD ke BPN',
            link: 'transaction/sspd/sspd-ke-bpn'
          },
        ]
      },
      {
        title: 'SKPDKB',
        fontIcon: 'duotone',
        icon: 'finance-calculator',
        link: 'transaction/skpdkb',
        guards: ['admin', 'superadmin', 'kabid'],
        subItems: [
          {
            title: 'Daftar SKPDKB',
            link: 'transaction/skpdkb/list-skpdkb',
          },
          {
            title: 'Pembayaran SKPDKB',
            link: '#',
          }
        ]
      },
      {
        title: 'Database Harga Pasar',
        fontIcon: 'duotone',
        guards: ['superadmin', 'admin'],
        guardSubBidang: ['pendataan & penetapan'],
        icon: 'disconnect',
        link: '#'
      }
    ]
  }
]