import { IMenu } from '../_menu-config'

export const DaftarPermohonan: IMenu[] = [
  {
    subItems: [
      {
        title: 'Pelayanan',
        guards: ['operator', 'superadmin', 'admin'],
        link: 'applications/application-list',
        fontIcon: 'duotone',
        guardSubBidang: ['pelayanan', 'pendataan pbb', 'admin_bidang_empty'],
        isVisible: false,

        icon: 'scroll',

        subItems: [
          {
            title: 'Semua Permohonan',
            rightTextCodeEl: 'DPMP',
            link: 'applications/application-list/all',
          },
          {
            title: 'Daftar Baru',
            rightTextCodeEl: 'DPMP',
            link: 'applications/application-list/daftar-baru',
          },

          {
            title: 'Perubahan Status NOP',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/status-nop',
          },
          {
            title: 'Pecah NOP ',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/pecah-nop',
          },
          {
            title: 'NOP FASUM ',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/nop-fasum',
          },
          {
            title: 'Update Data Bangunan',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/update-bangunan',
          },
          {
            title: 'Pembatalan SPPT',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/pembatalan-sppt',
          },
          {
            title: 'Mutasi Objek',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/mutasi-objek',
          },
          {
            title: 'Gabung NOP',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/gabung-nop',
          },
        ],
      },
    ],
  },
]
