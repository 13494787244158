import { ADJUSTMENT_SERVICE_MENU } from '../_data'
import { IMenu } from '../_menu-config'

export const AdminMenuBphtb: IMenu[] = [
    {
        guards: ['admin_bphtb'],
        guardSubBidang: ['pendataan & penetapan'],
        subItems: [
            {
                title: 'PPAT/NOTARIS',
                fontIcon: 'duotone',
                icon: 'people',
                link: 'master-data/ppat-notaris',
                guards: ['admin_bphtb'],
                adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
            },
            {
                title: 'Daftar SPPD',
                guards: ['admin_bphtb'],
                link: 'applications/application-list',
                fontIcon: 'duotone',
                adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
                isVisible: true,
                icon: 'scroll',
                guardSubBidang: ['pendataan & penetapan'],
                subItems: [
                    {
                        title: 'Permohonan Masuk',
                        // rightTextCodeEl: 'DPMP',
                        link: 'applications/daftar-sppd/permohonan-masuk',
                    },
                    {
                        title: 'Yang Diverifikasi',
                        guards: ['admin_bphtb'],
                        guardBidang: ['pendataan & penetapan'],
                        // rightTextCodeEl: 'DPPDP',
                        link: 'applications/daftar-sppd/selesai',
                    },
                    {
                        title: 'Yang Divalidasi',
                        guards: ['admin_bphtb'],
                        guardBidang: ['pendataan & penetapan'],
                        // rightTextCodeEl: 'DPD',
                        link: 'applications/daftar-sppd/validasi',
                    },
                    {
                        title: 'Daftar SSPD',
                        guards: ['admin_bphtb'],
                        link: 'transaction/sspd/daftar-sppd',
                    },
                    {
                        title: 'SSPD ke BPN',
                        guards: ['admin_bphtb'],
                        link: 'transaction/sspd/sspd-ke-bpn',
                    },
                ],
            },
            {
                title: 'Edit BPHTB',
                guards: ['admin_bphtb'],
                fontIcon: 'duotone',
                adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
                icon: 'message-edit',
                link: 'applications/edit-bphtb/edit',
            },
            {
                title: 'Edit Status Verifikasi',
                fontIcon: 'status',
                icon: 'sun',
                link: 'applications/edit-status-verifikasi',
                guards: ['admin_bphtb'],
            },
            {
                title: 'Pembatalan SSPD',
                guards: ['admin_bphtb'],
                fontIcon: 'duotone',
                adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
                icon: 'abstract-49',
                link: 'applications/pembatalan-sppd',
            },
            {
                title: 'Laporan',
                guards: ['admin_bphtb'],
                adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
                fontIcon: 'duotone',
                icon: 'graph-4',
                link: 'applications/laporan',
                subItems: [
                    {
                        title: 'Laporan SSPD',
                        link: 'applications/laporan/laporan-pembayaran-notaris',
                    },
                    {
                        title: 'Laporan Pembayaran SSPD',
                        link: 'applications/laporan/laporan-notaris',
                    },
                    {
                        title: 'Laporan SKPDKB',
                        link: 'applications/laporan/laporan-skpdkb',
                    },
                ]
            },
            {
                title: 'List Pembatalan SSPD',
                guards: ['admin_bphtb'],
                fontIcon: 'duotone',
                icon: 'eraser',
                link: 'applications/pembatalan-sppd'
            },
            {
                title: 'Query',
                guards: ['admin_bphtb'],
                guardSubBidang: ['pendataan & penetapan'],
                fontIcon: 'duotone',
                icon: 'questionnaire-tablet',
                link: 'applications/query',
                subItems: [
                    {
                        title: 'Pembayaran PBB-P2',
                        link: 'applications/query/pembayaran-pbb-p2',
                    },
                    {
                        title: 'Simulasi BPHTB',
                        link: '#',
                    },
                    {
                        title: 'Riwayat BPHTB',
                        link: '#',
                    },
                    {
                        title: 'Status BPHTB',
                        link: '#',
                    },
                ]
            },
            {
                title: 'Histori Wajib Pajak',
                guards: ['admin_bphtb'],
                fontIcon: 'duotone',
                adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
                icon: 'clipboard',
                link: 'applications/data-rekap-wajib-pajak/history-wajib-pajak',
            },

        ],
    },

]
